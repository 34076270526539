
// non scoped style for scoped slots
.sgirot-crud-list .item-action {
    font-size: 10px;
    background-color: var(--global-primary-background);
    color: var(--global-inverse-color);
    border-radius: 5px;
    padding: calc(var(--global-margin) * 0.25) calc(var(--global-margin) * 0.4);
    cursor: pointer;
    transition: opacity 150ms;

    &__text {
        margin-top: 2px;
    }

    &:hover {
        opacity: 0.8;
    }
}

.sgirot-crud-list{
    .filters-mobile {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
    }

    .form-filter-field {
        @media (max-width: 767px) {
            width: calc(50% - 10px);
            min-width: calc(50% - 10px);
            flex: 0 0 calc(50% - 10px);

            .wrap {
                width: 100%!important;
            }

        }
    }
}

